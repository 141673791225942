import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import files from './modules/files';
import users from './modules/users';
import orders from './modules/orders';
import userSites from './modules/userSites';
import siteTypes from './modules/siteTypes';
import sites from './modules/sites';
import siteStyle from './modules/siteStyle';
import siteSchedules from './modules/siteSchedules';
import siteSetting from './modules/siteSetting';
import entities from './modules/entities';
import entitySchedules from './modules/entitySchedules';
import entitySetting from './modules/entitySetting';
import entityDeliveries from './modules/entityDeliveries';
import entityDeliveryConditions from './modules/entityDeliveryConditions';
import categories from './modules/categories';
import products from './modules/products';
import groups from '@/store/modules/groups';
import options from './modules/options';
import discounts from './modules/discounts';
import discountProducts from './modules/discountProducts';
import discountSchedules from './modules/discountSchedules';
import news from './modules/news';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        files,
        users,
        orders,
        userSites,
        siteTypes,
        sites,
        siteStyle,
        siteSchedules,
        siteSetting,
        entities,
        entitySchedules,
        entitySetting,
        entityDeliveries,
        entityDeliveryConditions,
        categories,
        products,
        groups,
        options,
        discounts,
        discountProducts,
        discountSchedules,
        news,
    }
})
