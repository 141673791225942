import axios from '@/plugins/axios';

export default {
    namespaced: true,

    state: {
        entity: {}
    },

    mutations: {
        SET_ENTITY(state, payload) {
            state.entity = { ...payload };
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async get({ commit }, { entity_id }) {
            const { data } = await axios.get(`/admin/entities/${ entity_id }/setting`);
            data.delivery = data.delivery || { allow_delivery_outside_zones: false };
            data.payment = data.payment || {};
            data.yandex_delivery = data.yandex_delivery || {};
            commit('SET_ENTITY', data);
            return data;
        },
        async save({ commit, state }, { entity_id }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put(`/admin/entities/${ entity_id }/setting/${ state.entity.id }`, state.entity);
            } else {
                response = await axios.post(`/admin/entities/${ entity_id }/setting`, state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { entity_id, id }) {
            await axios.delete(`/admin/entities/${ entity_id }/setting/${ id }`);
        },
    },

    getters: {}
}
