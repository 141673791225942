import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","absolute":"","elevate-on-scroll":""}},[(_vm.user.id)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c(VSpacer),_vm._v(" "+_vm._s(_vm.user.phone)+" ")],1),_c(VMain,[_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,[_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'orders-list' })}}},[_c(VListItemTitle,[_vm._v("Заказы")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'sites-list' })}}},[_c(VListItemTitle,[_vm._v("Рестораны")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'discounts-list' })}}},[_c(VListItemTitle,[_vm._v("Акции и скидки")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'news-list' })}}},[_c(VListItemTitle,[_vm._v("Новости")])],1),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'users-list' })}}},[_c(VListItemTitle,[_vm._v("Пользователи")])],1),_c(VDivider),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v("Выйти")])],1)],1)],1)],1),_c('div',{staticClass:"ma-4"},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }