import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
        // redirect: {
        //     name: 'orders-list'
        // }
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/user/confirmation',
        name: 'user-confirmation',
        component: () => import('../views/user/Confirmation.vue')
    }, {
        path: '/users',
        name: 'users-list',
        component: () => import('../views/users/List.vue')
    }, {
        path: '/users/:id/detail',
        name: 'users-detail',
        component: () => import('../views/users/Detail.vue')
    }, {
        path: '/users/edit',
        name: 'users-create',
        component: () => import('../views/users/Edit.vue')
    }, {
        path: '/users/:id/edit',
        name: 'users-edit',
        component: () => import('../views/users/Edit.vue')
    }, {
        path: '/users/:id/sites',
        name: 'users-sites',
        component: () => import('../views/users/Sites.vue')
    }, {
        path: '/users/:id/orders',
        name: 'users-orders',
        component: () => import('../views/users/Orders.vue')
    }, {
        path: '/sites',
        name: 'sites-list',
        component: () => import('../views/sites/List.vue')
    }, {
        path: '/sites/:id/detail',
        name: 'sites-detail',
        component: () => import('../views/sites/Detail.vue')
    }, {
        path: '/sites/edit',
        name: 'sites-create',
        component: () => import('../views/sites/Edit.vue')
    }, {
        path: '/sites/:id/edit',
        name: 'sites-edit',
        component: () => import('../views/sites/Edit.vue')
    }, {
        path: '/sites/:id/setting',
        name: 'sites-setting',
        component: () => import('../views/sites/Setting.vue')
    }, {
        path: '/sites/:id/style',
        name: 'sites-style',
        component: () => import('../views/sites/Style.vue')
    }, {
        path: '/sites/:id/schedules',
        name: 'sites-schedules',
        component: () => import('../views/sites/Schedules.vue')
    }, {
        path: '/sites/:id/categories',
        name: 'sites-categories',
        component: () => import('../views/sites/Categories.vue')
    }, {
        path: '/sites/:id/products',
        name: 'sites-products',
        component: () => import('../views/sites/Products.vue')
    }, {
        path: '/sites/:site_id/products/:id/detail',
        name: 'sites-products-detail',
        component: () => import('../views/products/Detail.vue')
    }, {
        path: '/sites/:site_id/products/edit',
        name: 'sites-products-create',
        component: () => import('../views/products/Edit.vue')
    }, {
        path: '/sites/:site_id/products/:id/edit',
        name: 'sites-products-edit',
        component: () => import('../views/products/Edit.vue')
    }, {
        path: '/sites/:site_id/products/:id/options',
        name: 'sites-products-options',
        component: () => import('../views/products/Options.vue')


    }, {
        path: '/sites/:id/entities',
        name: 'sites-entities-list',
        component: () => import('../views/sites/Entities.vue')
    }, {
        path: '/sites/:site_id/entities/:id/detail',
        name: 'sites-entities-detail',
        component: () => import('../views/entities/Detail.vue')
    }, {
        path: '/sites/:site_id/entities/edit',
        name: 'sites-entities-create',
        component: () => import('../views/entities/Edit.vue')
    }, {
        path: '/sites/:site_id/entities/:id/edit',
        name: 'sites-entities-edit',
        component: () => import('../views/entities/Edit.vue')
    }, {
        path: '/sites/:site_id/entities/:id/schedules',
        name: 'sites-entities-schedules',
        component: () => import('../views/entities/Schedules.vue')
    }, {
        path: '/sites/:site_id/entities/:id/setting',
        name: 'sites-entities-setting',
        component: () => import('../views/entities/Setting.vue')
    }, {
        path: '/sites/:site_id/entities/:id/deliveries',
        name: 'sites-entities-deliveries',
        component: () => import('../views/entities/Deliveries.vue')

    }, {
        path: '/news',
        name: 'news-list',
        component: () => import('../views/news/List.vue')
    }, {
        path: '/news/:id/detail',
        name: 'news-detail',
        component: () => import('../views/news/Detail.vue')
    }, {
        path: '/news/edit',
        name: 'news-create',
        component: () => import('../views/news/Edit.vue')
    }, {
        path: '/news/:id/edit',
        name: 'news-edit',
        component: () => import('../views/news/Edit.vue')

    }, {
        path: '/discounts',
        name: 'discounts-list',
        component: () => import('../views/discounts/List.vue')
    }, {
        path: '/discounts/:id/detail',
        name: 'discounts-detail',
        component: () => import('../views/discounts/Detail.vue')
    }, {
        path: '/discounts/edit',
        name: 'discounts-create',
        component: () => import('../views/discounts/Edit.vue')
    }, {
        path: '/discounts/:id/edit',
        name: 'discounts-edit',
        component: () => import('../views/discounts/Edit.vue')
    }, {
        path: '/discounts/:id/schedules',
        name: 'discounts-schedules',
        component: () => import('../views/discounts/Schedules.vue')
    },  {
        path: '/orders',
        name: 'orders-list',
        component: () => import('../views/orders/List.vue')
    }, {
        path: '/orders/:id/detail',
        name: 'orders-detail',
        component: () => import('../views/orders/Detail.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
