import moment from 'moment';
import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {
        statuses: {
            orders: {},
            payments: {}
        },
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_STATUSES(state, { orders, payments }) {
            state.statuses.orders = orders;
            state.statuses.payments = payments;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload.results;
            state.entitiesCount = payload.total;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async statuses({ commit }) {
            const { data: orders } = await axios.get('/common/orders/statuses');
            const { data: payments } = await axios.get('/common/payments/statuses');
            commit('SET_STATUSES', { orders, payments });
        },
        async fetch({ commit }, { filter = {}, sorting = null, page = 1, itemsPerPage = 15 }) {
            const { data } = await axios.get('/admin/orders', { params: { filter, sorting, page, itemsPerPage } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async export({ commit }, { filter = {}, sorting = null }) {
            const response = await axios.get('/admin/orders/export', { responseType: 'blob', params: { filter, sorting } });
            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');
            anchorElement.href = href;
            anchorElement.download = `${ moment().format('YYYY-MM-DD-HH-mm') }-marketoria-orders-export.csv`;
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/orders/${ id }`);
            commit('SET_ENTITY', data);
        },
        async setStatus({ commit }, { id, status }) {
            const { data } = await axios.put(`/admin/orders/${ id }/status`, { status });
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put(`/admin/orders/${ state.entity.id }`, state.entity);
            } else {
                response = await axios.post('/admin/orders', state.entity);
            }
            return response.data;
        },
        async checkDeliveryClaim({ dispatch }, { id }) {
            const { data } = await axios.get(`/admin/orders/${ id }/delivery-claims/check-price`);
            dispatch('get', { id });
            return data;
        }, 
        async createDeliveryClaim({ dispatch }, { id }) {
            const { data } = await axios.post(`/admin/orders/${ id }/delivery-claims`);
            dispatch('get', { id });
            return data;
        }, 
        async acceptDeliveryClaim({ dispatch }, { id }) {
            const { data } = await axios.post(`/admin/orders/${ id }/delivery-claims/accept`);
            dispatch('get', { id });
            return data;
        }, 
        async cancelDeliveryClaim({ dispatch }, { id }) {
            const { data } = await axios.post(`/admin/orders/${ id }/delivery-claims/cancel`);
            dispatch('get', { id });
            return data;
        }, 
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/orders/${ id }`);
        },
    },

    getters: {}
}
