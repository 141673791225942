import axios from '@/plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }, { entity_id }) {
            const { data } = await axios.get(`/admin/entities/${ entity_id }/deliveries`);
            for(let deliveryIndex = 0; deliveryIndex < data.length; deliveryIndex ++) {
                data[deliveryIndex].payments = data[deliveryIndex].payments || {};
                data[deliveryIndex].formatedMinOrderPrice = data[deliveryIndex].min_order_price / 100;
                for(let index = 0; index < data[deliveryIndex].conditions.length; index ++) {
                    data[deliveryIndex].conditions[index] = {
                        ...data[deliveryIndex].conditions[index],
                        deliveryIndex,
                        index
                    }
                }
            }
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { entity_id, id }) {
            const { data } = await axios.get(`/admin/entities/${ entity_id }/deliveries/${ id }`);
            commit('SET_ENTITY', data);
        },
        async save({ commit, state }, { entity_id }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put(`/admin/entities/${ entity_id }/deliveries/${ state.entity.id }`, state.entity);
            } else {
                response = await axios.post(`/admin/entities/${ entity_id }/deliveries`, state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { entity_id, id }) {
            await axios.delete(`/admin/entities/${ entity_id }/deliveries/${ id }`);
        },
    },

    getters: {}
}
